/* bottomnavigator.css */
.bottom_navigation{
  margin-top: 36px;
  margin-left: -32px;

}
@media(min-width:360px),(min-width:450px){
  .bottom_navigation {
    margin-top: -5px;
    margin-left: -15px;
    width: 91%;
    position: fixed;
}


 


}
