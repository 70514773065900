/* incomingCall.css */

.incoming-call-container {
    /* border: 1px solid #53a9e5;
    border-radius: 8px;
    background-color: #53a9e522;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    margin-top: 1px;
    background-color: #53a9e5;
    
  }
  .incoming-call-top-container{
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    padding-bottom: 269px;  
}
.incoming-call-bottom-container{
    background-color: red;
    margin-top: -235px;
    border-top-left-radius: 22px;
    border-top-right-radius: 21px;
    background-color: white;
}

  .calltye-header{
    color: white;
    font-weight: bold;
   margin: 9px;
   position: relative;
   font-size: 19px;
  }
  /* Animation for dots */
  @keyframes dots {
    0%, 20% {
      content: "."
    }
    40% {
      content: ".."
    }
    60% {
      content: "..."
    }
  }
  


  .calltye-header::after{
    content: "...";
    animation: dots 1.5s infinite steps(1);
    display: inline-block;
    width: 3ch; /* Adjust the width as needed */
    text-align: left;
    position: absolute;
    top: 0;
    
    color:  white;
  }

  .letter-image {
   
    width: 59px;
    height: 59px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 103px;
    margin-top: 40px;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

 .animate-letter-image {
    animation-name: scaleAnimation;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  
  @keyframes scaleAnimation {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }

  .incomingcaller-info {
    /* margin-bottom: 11px; */
    font-size: 22px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin-top: 23px;
    margin-left: -16px;
    font-family: Arial, Helvetica, sans-serif;
}
  
.call-actions {
    display: flex;
    gap: 105px;
    margin-top: 33px;
    margin-left: 50px;
}
  
  .call-actions button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #ffff;
    transition: color 0.3s ease;
    margin-left: -2px;
  }
  
  .call-actions button:hover {
    color: #007bff;
  }
  .answer-button {
    background-color: #40ff00;
    border-radius: 29px;
    /* width: 16%; */
    padding: 11px;
    animation: zoomInOut 1s infinite alternate;
    /* animation-name: scaleButtonAnimation; */
}
 
.decline-button {
    background-color: red;
    border-radius: 26px;
    padding: 11px;
    /* width: 20%; */
    animation: zoomInOut 1s infinite alternate;
}

.call-info-title{
    color: #2196F3;
    margin-left: 90px;
    font-size: 24px;
    padding: 8px;
}

.ib-call-info {
    color: #2196F3;
    font-size: 14px;
    /* display: grid; */
    /* margin-left: 34px; */
    padding-bottom: -33px;
    grid-row-gap: 22px;
    margin-top: 8px;
    font-family: Arial, Helvetica, sans-serif;
    
}

.ib-call-info-data-container{
  column-count: 2;
  margin-top: 16px;
  margin-left: 7px;
}
.insight-nodata{
  color: #2196F3;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 89px;
  margin-top: 48px;
  font-size: 22px;
}








@keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes zoomInOut {
    0% {
        transform: scale(1);
    }
  
    100% {
        transform: scale(1.1);
    }
  }
  
  
.animate-answer-button {
    animation-name: scaleAnimation;
    animation-duration: 0.5s;
  }
  .call-insight-value{
    margin-left: -30px;
  }