.dashboard-row {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 25px; */
    margin-top: 94px;
    /* margin-left: 9px; */
  }
  
  .dashboard-cardview {
    width: 35%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 19px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    margin-bottom: -1px;
    margin-top: -29px;
  }
  
  .dashboard-cardview:hover {
    transform: scale(1.05); /* Small zoom effect on hover */
  }
  
  .card-content {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
  }
  
  .card-icon {
    width: 28px;
    height: 29px;
    margin-bottom: 10px;
    margin-left: 102px;
  }
  
  .card-text {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    margin-top: -50px;
  }
  
  .card-title {
    font-size: 16px;
    /* color: #777; */
    margin-top: 24px;
    margin-left: -15px;
  }
  
  .dashboard-container {
    /* padding: 20px;
    background-color: #f9f9f9; */
  }
  
 
  