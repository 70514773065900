/* LoginForm.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color:#fff 
    /* #f0f0f0; */
  }
  
  .login-box {
    background-color: #fff;
    width: 210px;
    margin-left: 27px;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 42px;
}
  
  h2 {
    font-size: 22px;
    margin-bottom: 29px;
    margin-right: 205px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-right: 136px;
    margin-bottom: 11px;
    font-size: 15px;
    margin-top: 30px;
    color: #2196F3;
    font-weight: bold;
}
  
  .logininput{
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-button {
    width: 50%;
    padding: 10px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:disabled {
  background-color:#ccc; /* Gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor */
  color: #666; /* Darker text when disabled */
}
  
  /* .login-button:hover {
    background-color: #0056b3;
  } */
  .company-logo {
    max-width: 52%;
    height: auto;
   
  }
  .margin-border{
    border: 15px;
    /* background-color: aqua; */
  }
  
  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } 
  
  .pwd-eyeicon{
    margin-left: -22px;
  }
  
  .error-message_{
    color: red;
    font-size: 12px;
    margin-left: -8px;
    font-weight: 600;
    margin-top: 6px;
  }
  
  
 