
.queue-logo {
    text-align: center;
    width: 23%;
    margin-left: 120px;
    margin-top: 8px;
}

.queue-placeholdertext {
    color: #2196F3;
    font-weight: bold;
    margin-left: 43px;
    margin-top: 23px;
    font-size: 19px;
}


.queue-selectbox {
    margin-left: 35px;
    margin-top: 21px;
    width: 76%;
    height: 121px;
}

.queue-selectinlinestyle {
    background-color: #ffff;
    width: 139%;
    height: 43px;
    border-color: #3e98c7;
    border-radius: 7px;
    color: #3e98c7;
}

.queue-button {
    margin-top: 68px;
    padding: 11px;
    width: 229px;
    border-radius: 13px;
    font-weight: bold;
    margin-left: 41px;
    background-color: #2196F3;
    color: white;
    font-size: 19px;
    cursor: pointer;
}
.queue-button:disabled {
    background-color: #ccc; /* Gray background when disabled */
    cursor: not-allowed; /* Not-allowed cursor */
    color: #666; /* Darker text when disabled */
  }

.bgcover {
    background-image: url('../images/bgcoverimg.svg');
    height: 409px;
    width: 100%;
}

.queue-error-message {
    color: red;
    font-weight: bold;
    font-size: smaller;
    margin-left: 56px;
}
.queues-select-message{
color :#2196F3;
font-weight: bold;
    font-size: smaller;
    margin-left: 56px;

}
.queue-textbox{
    margin-top: 39px;
    margin-left: 37PX;
    width: 72%;
    height: 34px;
    border-radius: 7px;
}

.back-to-login {
    display: block;
    margin-top: 6px;
    /* text-align: center; */
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
    margin-left: 46px;
    font-size: 15px;
  }
  
  .back-to-login:hover {
    color: #0056b3; /* Darker shade on hover */
    font-weight: bold;
  }
  

@media(min-width:360px),(min-width:450px){
    .bgcover {
        background-image: url(http://localhost:3000/static/media/bgcoverimg.b83c7cc….svg);
        height: 340px;
        width: 80%;
        margin-left: 32px;
        margin-top: -11px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 22px;
    }

    .queue-placeholdertext {
        color: #2196F3;
        font-weight: bold;
        margin-left: 17px;
        margin-top: 28px;
        font-size: 19px;
        padding-top: 20px;
        /* margin-top: 25px; */
        margin-bottom: -2px;
    }

    .queue-button {
        margin-left: 26px;
        
    }

    .queue-selectbox {
        margin-left: 35px;
        margin-top: 21px;
        width: 76%;
        height: 43px;
    }


}   