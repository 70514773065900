.webphone-top-container{
    background-color:#fff;
     
}

.call-duration-status {
    /* background-color: red; */
    margin-left: 206px;
    color: #2196F3;
    text-align: center;
    font-weight: bold;
    margin-top: 3px;
    font-family: Arial, Helvetica, sans-serif;
}
.img-circle {
    width: 45px;
    margin-left: 110px;
    height: 59px;
    position: fixed;
    animation: zoomInOut 1s infinite alternate;
    margin-top: -43px;
}
.call-animation {
    margin-right: 2px;
    margin-top: 21px;
    position:fixed;
}


.caller-info {
    text-align: center;
    font-size: 19px;
    color: #2196F3;
    font-weight: bold;
    margin-left: 39px;
    margin-top: 36px;
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
}

.Outbound{
    margin-left: 79px;
}


.webphone-bottom-container {
    background-color: #ffff;
    padding-bottom: 197px;
    margin-top: 108px;
    border-top-left-radius: 23px;
    border-top-right-radius: 20px;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1);
    /* position: fixed; */
    /* margin-left: -11px; */
}
.webphone-bottom-container-row1{
    display: flex;
    margin-left: 24px;
} 
.webphone-bottom-container-row2{
    display: flex;
    margin-left: 24px;
} 
.webphone-bottom-container-row3{
    display: flex;
    margin-left: 127px;
} 
.webphone-icons {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 103%;
    margin-top: 29px;
    margin-left: -9px;
    padding-right: 92px;
    border: none;
    border-radius: 8px;
    background-color: white;
    margin-top:39px
    
}
.icon-label {
    font-size: 15px;
    color: #2196F3;
    text-align: center;
    margin-top: 18px;
    margin-right: 0px;
    text-align: center;
}












  .row {
    display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -14px;
      margin-right: 9px;
  }

.top-control{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 7px;
    margin-top: -22px;
    width: 231px !important;
  }
  .webphone-button-container{
    background-color: red;
  }
  
 
  
  @keyframes zoomInOut {
    0% {
        transform: scale(1);
    }
  
    100% {
        transform: scale(1.1);
    }
  }