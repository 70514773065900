.callnow {
    background: #32AA52 !important;
    border-radius: 0px;
    border: 0px;
    padding: 10px;
  }
  
  .callnow:hover {
    box-shadow: none;
    background: #26853f !important;
  }
  
  
  .pad {
    background: #fff;
    border: 0px;
    color: #53a9e5;
    text-align: center;
    border: 1px solid #fff;
    font-size: 24px;
    margin-top: -5px;
    width: 58%;
    margin-left: -133px;
}
  
  .pad:focus {
    box-shadow: none;
    color: #000000;
    background: #fff;
  }
  .dialnumber div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .dialnumber span {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 7px;
    color: #3e98c7;
    cursor: pointer;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em
  }
  
  .dialnumber span:hover {
    color: #ffffff;
    background: #3e98c7;
  }
  
  .dialnumber {
    border-top: 0.5px solid #eaf6ed;
  }
  
  
  
  
  .numberpad span {
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  /* .numberpad {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
  } */
  .dialpad {
    width: 108%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-top: 13px;
    margin-left: 185px;
    /* background-color: red; */
}
     
.dialpad_sub_container {
  width: 79%;
  margin-left: -169px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
  
.contact_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 4px;
  margin-top: 10px;
}

.close-icon {
  border-radius: 20px;
  margin-bottom: -7px;
  margin-left: 363px;
  margin-top: 9px;
  
}
.call-button {
  background-color: #07bc0c;
  border-radius: 32px;
  width: 17%;
  /* height: 19%; */
  padding: 16px;
  border: none;
  margin-top: 11px;
  margin-left: 66px;
}
.call-button:disabled {
  background-color:#ccc; /* Gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor */
  color: #666; /* Darker text when disabled */
}
