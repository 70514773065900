.dispopage-container{
  margin-top: -38px
}

.dispo-instruct {
    color: #3498db;
    margin-top: 21px;
    text-align: center;
}
  
  .call-notes textarea {
    width: 83%;
    border: 1px solid #ccc;
    resize: vertical;
    margin-left: 24px;
    margin-top: 44px;
    }  
  
  .call-notes textarea:focus {
    border: 1px solid #53a9e5; /* Example border color when the textarea is focused */
  }
  
  .call-notes textarea:hover {
    border: 1px solid #999; /* Example border color when the mouse hovers over the textarea */
  }

  .disposition-status-dropdown {
    margin-top: 10px;
  }
  
  .disposition-status-dropdown label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .disposition-status-dropdown select {
    width: 85%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 25px;
    margin-top: 42px;
}

  .white-select {
    background-color: white; /* Set the background color to white */
    /* Add any other desired styles here */
  }

  .dispo-button {
    margin-top: 52px;
    margin-left: 113px;
    background-color: #007bff;
    padding: 8px;
    width: 100px;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;


    /* margin-top: 52px;
    margin-left: 123px;
    background-color: #3498db;
    padding: 8px;
    width: 100px;
    color: white;
    font-weight: bold; */
}

.dispo-button:disabled {
  background-color:#ccc; /* Gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor */
  color: #666; /* Darker text when disabled */
}



.leadstatus-status-dropdown {
  margin-top: 10px;
  width: 180%;
}

.leadstatus-status-dropdown label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.leadstatus-status-dropdown select {
  width: 47%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 25px;
  margin-top: 26px;
}

.leadstatus-update-button{
  margin-top: -43px;
  margin-left: 184px;
  padding: 8px;
  width: 100px;
  color: white;
  font-weight: bold;
  border: none;
}
.leadstatusinline-update-button{
  background-color: #3498db;
  color: white;
  font-weight: bold;
}
.leadstatusinline-update-button:disabled {
  background-color:#ccc; /* Gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor */
  color: #666; /* Darker text when disabled */
}