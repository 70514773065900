.topheader {
  background-color: #2196F3;
  padding: 11px;
  padding-bottom: 178px;
  height: 186px;
}
.contentspace {
  position: fixed;
  width: 96%;
  background-color: #ffff;
  padding-bottom: -1px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -352px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 352px;
  margin-top: -326px;
  scrollbar-width: thin;
  scrollbar-color: white;
}

.logo-image {
  height: 43px;
    margin-left: 1px;
    margin-top: -8px;
    width: 42%;
}
  
  .button-logout {
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 7px;
    width: 108px;
    margin-top: -53px;
    margin-left: 201px;
}
  
  .betweenspace {
    margin-right: -79px;
    background-color: #2196F3;
    padding: 5px;
    border-radius: 20px;
    margin-left: -83px;
    margin-top: 15px;
  }
  .user-options {
    display: none;
    position: absolute;
    background-color: #fff;
    color: #070707;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .option {
    padding: 7px;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: -7px;
  }
  /* Add this CSS to your stylesheet */
  .away-options {
    position: absolute;
    top: 100%; /* Position it below the "Away" badge */
    left: 0;
    background-color: #fff; /* Background color for the options */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    z-index: 100; /* Ensure it appears above other content */
  }
  
  .away-options .option {
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    color:black;
  }
  
  .away-options .option:hover {
    background-color: #f0f0f0; /* Background color on hover */
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }
  
  .userlogo {
    height: 28px;
    margin-top: -22px;
  }
  .userlogout {
    height: 28px;
    margin-left: 40px;
    border-radius: 24px;
    margin-top: 10px;
    background-color: #2196F3;
    margin-right: 4px;
}
  
  
  .badge-green {
    background-color: #28a745;
      color: #fff;
      width: 6px;
      height: 7px;
      border-radius: 78%;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      margin-left: 23px;
      margin-top: -34px;
      position: absolute;
  }
  
  
  .badge-red {
    background-color: #cc0000;
    color: #fff;
    width: 6px;
    height: 7px;
    border-radius: 78%;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    margin-left: 23px;
    margin-top: -34px;
    position: absolute;
  }
  .badge-grey {
    background-color: orange;
    color: #fff;
    width: 6px;
    height: 7px;
    border-radius: 78%;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    margin-left: 23px;
    margin-top: -34px;
    position: absolute;
  }
  /***************************** Agent Pause Page ****************************************************/
  .agent-pause {
    
    background-color: #fff;
      color: #fff;
      font-size: 18px;
      text-align: center;
      height: 100%;
      width: 97%;
      margin-top: -37px;
      padding: 69px;
  }
  .pause-illust {
    display: inline-block;
    width: 71%;
    height: auto;
    max-width: calc(100vh - 184px);
    object-fit: contain;
    margin-top: -18px;
    margin-left: -190px;
    padding-right: -55px;
    padding-left: 17px;
    border-radius: 14px;
}
  .pause-notes{
    color:#2196F3;
    margin-right: 133px;
  }
  
  .dial-button {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px;  /* Adjust as needed */
    background-color: #007bff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears on top */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  .dial-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  