.sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Hidden by default */
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: left 0.3s ease;
    padding: 20px;
    z-index: 1000; /* Make sure it overlays the main content */
}

.sidebar.open {
    left: 0; /* Show sidebar */
}

.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
}

.sidebar h2 {
    margin: 0 0 20px 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    padding: 10px 0;
    cursor: pointer;
}

.sidebar ul li:hover {
    background-color: #444; /* Highlight on hover */
}
