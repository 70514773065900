.contact-items{
  margin-top: 4px;
  overflow-y: auto;
  max-height: 312px;
}
.main-container {
    max-width: 1200px; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add padding around the content */
    background-color: #f5f5f5; /* Background color for the container */
    border-radius: 8px; /* Add rounded corners to the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow to the container */
  }
  
  /* Additional styles for content within the main container can be added here */
  
  .search-bar {
      display: flex;
      align-items: center;
      max-width: 300px;
      margin-bottom: 20px; /* Add margin for spacing */
    }
    
    /* Style for the search input field */
    .search-input {
      flex: 1; /* Take up available space */
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
    }
    
    /* Style for the search button */
    .search-button {
      background-color: #007bff; /* Change to your desired button color */
      color: #fff; /* Text color */
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      margin-left: 10px; /* Add spacing between input and button */
      cursor: pointer;
      font-size: 16px;
    }
    
    /* Hover effect for the search button */
    .search-button:hover {
      background-color: #0056b3; /* Change to a darker shade on hover */
    }
    
    .no-data-message {
      font-size: 24px;
      color: #777; /* Change the color to your preference */
      margin-top: 20px;
      text-align: center;
      padding: 92px;
    }
  
  .call-log-container {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f5f5f5;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    
    h2 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .contact-list-title {
      font-size: 24px;
      color:black;   
      margin-left: 52px;
      margin-top: 60px;
    }
    .search-container {
      position: relative;
    display: flex;
    align-items: center;
      /* text-align: center;
      margin-bottom: 20px;
      padding-left: 30px; */
    }
    
  
    .searchinput{
      width: 100%;
      padding: 10px;
      border: 1px solid #d31b1b;
      border-radius: 4px;
      font-size: 16px;
    }
    .calllogsearch {
        width: 85%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 28px;
        font-size: 12px;
        margin-left: 40px;
    }
    
  .contact-list {
    list-style: none;
    max-height: 426px;
    overflow-y: hidden;
    padding: 0;
    /* margin-top: -147px; */
    margin-left: -39px;
    margin-top: -47px;
}
    
.contact-item {
  display: flex;
  margin-bottom: -14px;
  padding: 1px;
  border-radius: 4px;
  z-index: 1;
  padding-left: 12px;
  height: 57px;
  width: 93%;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
align-items: flex-start;
}
    /* Add this CSS rule to your CallLog.css or your preferred CSS file */
    .contact-items .call-date {
        font-size: 10px;
        color: #777;
        margin-top: -20px;
        margin-left: 121px;
    }
    
  .contact-items .call-type {
    font-size: 9px;
    color: #777;
    margin-top: 0px;
    /* margin-left: -12px; */
  }
  .contact-items .pnumber {
    font-size: 12px;
    color: black;
    /* margin-top: 9px; */
    /* margin-left: -12px; */
  }
  .contact-image {
    width: 38px;
    height: 45px;
    border-radius: 49%;
    margin-right: 13px;
}
  
    
    .contact-details {
      flex-grow: 1;
    }
    .contact-details h5{
      margin-top: 0px;
      margin-bottom: 0px;
    }
    h3 {
      font-size: 18px;
      margin: 0;
    }
    
    p {
      font-size: 14px;
      margin: 5px 0;
    }
    
    .pagination {
      text-align: center;
      margin-top: 20px;
    }
    
    /* button {
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      margin: 0 5px;
      cursor: pointer;
    }
    
    button.active {
      background-color: #0056b3;
    } */
    
  
  
    /* Add this CSS to your calllog.css or a separate CSS file */
  .keyboard-icon {
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    color:green; /* Text color */
    border: none;
    border-radius: 50%; /* Makes it a circular button */
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    z-index: 9999; /* Ensure it's above other elements */
  }
  
  .keyboard-icon:hover {
    background-color: #f5f7fa; /* Change the background color on hover */
  }
  
  /* custom-scrollbar.css */
  
  /* Track */
  ::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar track */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    
    background: whitesmoke; /* Color of the scrollbar handle */
    border-radius: 4px; /* Rounded corners for the handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:whitesmoke; /* Color of the handle on hover */
  }

  .audiofile {
    position: absolute;
    bottom: 0px;
    right: 10px;
    z-index: 1000;
    padding: 10px;
}
  
.PhoneNumberWrap_rht{
  margin-left: 91px;
  margin-top: -28px;
}
.faCheck{
  color: #0bd60b;

}
.faBan{
  color:gray;
}
.faPhoneSlash{
  color: #d31b1b;
}

.faPhone{
  color: green;
}
.icons {
  display: flex;
    gap: 10px;
    margin-top: -18px;
    margin-left: 156px;
 /* Add some space between phone number and icons */
}
.icon-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color:blue;
}

.call-duration {
  font-size: 12px;
  color: #0f0101;
  /* margin-left: 40px; */
}
.rec-icon{
  color:#2196F3
}
  
@media(min-width:360px),(min-width:450px){
  .contact-items {
    margin-top: 4px;
    overflow-y: auto;
    max-height: 276px;
}

}
  