/* moduletype */

.automodule-multi-select {
    width: 70%;
    margin-left: 6px;
}

.auto-module-dropdown {
    margin-left: 4px;
    width: 73%;
}

.entitydropdown {
    margin-left: 6px;
    margin-top: 9px;
}

.entypeinline {
    width: 100%;
}

.entitydropdown select {
    width: 81%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;

}

/**************************************Search Icon ****************************/
.search-icon {

    left: 242px;
    margin-top: -29px;

}

.auto-serchicon {
    margin-top: -18px;
    margin-left: 263px;
    position: fixed;
}

/* leadstatus */

.l_statusdropdown {
    margin-left: 23px;
    margin-top: -4px;
    display: flex;
    flex-flow: row;
    
}

.l_statusinline {
    width: 100%;
}

.l_statusdropdown select {
    width: 76%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 120px;
    font-size: 10px;
    /* width: 118%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px; */

}

.customview_dropdown {
    margin-left: 23px;
    margin-top: -4px;
    display: flex;
    flex-flow: row;
    
}

.customview_dropdown {
    width: 100%;
}

.customview_dropdown select {
    width: 76%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 120px;
    font-size: 10px;
  

}


/*********************************Call Status **************************************/

.call_statusdropdown {
    margin-left: -43px;
    margin-top: -13px
}

.call_statusinline {
    width: 100%;
}

.call_statusdropdown select {
    width: 65%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 120px;
    font-size: 10px;
    /* width: 118%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px; */

}




.rowview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* Distribute space between the two dropdowns */
    margin-top: 22px;
    margin-left: -18px;

}

.call_statusdropdown:nth-child(2) {

    margin-left: -13px;
    /* Add additional space between Status and Source */
    /* Subtracting margin from the width to maintain proper spacing */
}

/*************************************Play And Resume Icon ****************************************************/
.icon-container {
    position: fixed;
    display: flex;
    margin-left: 221px;
    margin-top: -27px;
}

.start-icon,
.play-icon {
    font-size: 24px;
    /* Adjust the icon size */
    color: #333;
    /* Adjust the icon color */
    margin-right: 10px;
    cursor: pointer;
    /* Adjust the space between icons */
}

/**********************************Dropdown Content*****************************************/
.dropdown-content-container {
    height: 175px;
    border-bottom-left-radius: 0px;
    padding: 7px;
}

/************************************Show leads and skiped data placeHolder*********************************/



.auto-placeholder {

    display: flex;
    flex-direction: row;
    /* width: 97%;
    margin-top: -1px;
    margin-left: -49px; */

}

.auto-leadpholder-container {
    /* display: flex;
    flex-direction: row; */
    /* width: 29%;
    border-radius: 4px;
    margin-left: 54px; */

}

.auto-leadpholder {
    width: 31%;
    position: relative;
    border-radius: 15px;
    background-color: #2196F3;
    height: 28px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-left: 9px;
}

.auto-skippedpholder-container {
    margin-left: 3px;
    width: 39%;
    border-radius: 4px
}

.auto-skippedpholder {
    margin-left: -4px;
    width: 90px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    color: #555555;
    font-weight: bold;
}

.auto-autoskipcount {
    margin-left: 50%;
    background-color: #3e98c7;
    padding-right: 15px;
    border-radius: 4px;
}

/*************************************auto-leadskipdata list ***********************************************/
.no-data-message {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    color: #555;
    /* Adjust color as needed */
    margin-top: 58px;
    margin-left: -6px;
}

.auto-leadskipdata-listcontainer {
    /* background-color: whitesmoke;
    border: 1px solid #ccc;
    
    margin-left: -49px; */
    height: 250px;
    width: 100%;
    overflow-y: auto;
}

.auto-leadlist {
    margin-top: -2px;
    padding: 1px;
    background-color: white;
    padding-left: 6px;
}

.auto-leadinfo {
    color: #666666;
    margin-top: -57px;
    margin-left: 27px;
}

.auto-checkbox {
    background-color: rebeccapurple;
    margin-left: 6px;
    height: 57px
}

.lead-countautodial {
    font-size: 21px;
    width: 40px;
    margin-left: 143px;
    margin-top: -26px;

}

.leaddata-loader {
    /* background-color: red; */
    margin-left: 110px;
    margin-top: 68px
}

.leadstatus {

    color: #3e98c7;
    margin-left: 169px;
    margin-top: -22px;
}

.disablebtn {
    pointer-events: none;
    opacity: 0.5;
}

.auto-placeeholder-container{
    margin-top: -81px;
    margin-left: 9px;
}
.auto-inside-placeholder {
    margin-top: 8px;
    margin-left: 12px;
}

/******************************************New Auto-dial UI*******************************************/
.auto-list-container{
    width: 94%;
    position: relative;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-color: #fff;
    height: 231px;
    margin-left: 11px;
    margin-top: 3px
}
.dialler-nodata{
    margin-top: 31px;
    width: 35%;
    margin-left: 82px;
}
.no-data-found {
    font-size: 15px;
    font-family: Poppins;
    color: #373737;
    margin-left: 94px;
    margin-top: 22px;
}
.dialler-contact-image{
    width: 30px;
    height: 45px;
    border-radius: 49%;
    /* margin-right: -46px; */
    margin-top: -30px;
    margin-left: 27px;
}
.dialler-checkbox{
    margin-top: 27px;
}
.dialler-list-view{
    margin-top: -47px;
    margin-left: 67px;
    display: flex;
    flex-direction: column;
}
.dialler-lead-status{
    margin-left: 136px;
    margin-top: -28px;
    color: #3498db
}
.diallertake-textbox{
    margin-top: 2px;
}
.dialler-data-count-inline{
    margin-left: 195px;
    width: 27%;
    height: 30px;
    border-radius: 7px;
}
.dialler-data-count-outline{
    margin-top: -37px;
}
.dialler-waiting{
    margin-top: 40px;
    width: 48%;
    margin-left: 70px;
}

.additional-content {
    text-align: center;
    margin-top: 10px;
  }
  
  .yes-button, .no-button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .dialler-yes-no-buttoncontainer{
    margin-top: 16px;
  }
  .yes-button {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  
  .no-button {
    background-color: #f44336; /* Red */
    color: white;
  }
  .more-data-text{
   margin-top: 17px;
  }
  .dialler-dooctiloader{
    margin-top: 93px;
    width: 38%;
    margin-left: 81px;
  }
  .auto-reseticon{
    margin-top: -31px;
    margin-left: 260px;
  }