.transfercall {
  height: 261px;
  width: 101%;
  /* z-index: 10000; */
  position: absolute;
  top: 147px;
  left: 1px;
  border-radius: 5px;
  background-color: #fff;
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow-y: scroll;
}
  .transferheader{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 4px;
  }
  .close-icon-transfer {
    background-color: red;
    margin: 5px;
    padding: 5px;
    border-radius: 50px;
    font-size: 12px;
    height: 13px;
    margin-top: 8px;
  }
  .transferheader_title{
    margin: 10px;
    text-align: center;
    width: 90%;
    color: #2196F3;
    font-weight: bold;
    margin-left: -17px;
    font-size: 19px;
  }
  .cotainer_transfer{
    height: 200px;
    overflow-y: auto;
  }
  .options-dropdown select {
    /* width: 143%; */
    padding: 8px;
    width: 240px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 6px;
    margin-left: 24px;
  }
  .contact-details_data{
    /* background-color: #ccc; */
    margin: 3px;
    border-radius: 5px;
    cursor: pointer;
    overflow-y: scroll;
  }
  
  .pad_ {
    background: #fff;
    border: 0px;
    text-align: center;
    border: 1px solid #1e1c1c;
    font-size: 16px;
    margin-top: 15px;
    width: 73%;
    height: 28px;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    margin-left: 35px;
    
  }
  
  .pad_:focus {
    box-shadow: none;
    color: #000000;
    background: #fff;
  }
  .trans_nodata {
    color: #2196F3;
   text-align: center;
    margin-top: 56px;
    margin-left: -47px;
    font-size: 16px;
    font-weight: bold;
    
}


.options-nav {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  /* background-color: #f0f0f0; */
  border-radius: 5px;
  margin-left: -21px;
  margin-top: -7px;
}

.nav-button {
  padding: 10px 20px;
  margin: 0 10px; /* Add horizontal space between buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff; /* Primary color */
  color: white;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.nav-button.active {
  background-color: #0056b3; /* Active color */
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.header-icons {
  display: flex;
  align-items: center;
}

.back-icon-transfer {
  cursor: pointer;
    /* margin-right: 29px; */
    position: fixed;
    margin-left: 73%;
}

.back-icon-transfer:hover {
  color: #007bff; /* Change color on hover */
}

.transferperson-img-inline{
width: 9%;
}
.transferperson-img-outline{
margin-top: 2px
}
.transferdata{
  margin-top: -31px;
  margin-left: 37px;
}
.transfer-queue-data{
  margin-top: 13px;
    margin-left: 16px;
}
.transfer-queue-phone-icon{
  margin-top: -22px;
  margin-left: 251px;
}
.transfer-agent-phone-icon{
margin-top: 2px;
}
.transfer-button{
  background-color: green;
  height: 40px;
  width: 34%;
  margin-left: 113px;
  margin-top: 37px;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 17px;
}
.transfer-no-data-found {
  font-size: 15px;
  font-family: Poppins;
  color: #373737;
  margin-left: 46px;
  margin-top: 22px;
}
.transfer-nodata-img{
  margin-top: -30px;
  width: 25%;
  margin-left: 25px;
  }

  .transfer-queue-nodata-img {
    margin-top: 21px;
    width: 32%;
    margin-left: 102px;
}

.transfer-queue-nodata-found {
    font-size: 15px;
    font-family: Poppins;
    color: #373737;
    margin-left: 111px;
    margin-top: 22px;
}                        